import * as React from "react"
import logoInverse from '../images/logo-inverse.svg'
import phone from "../images/icons/phone.svg"
import homePhone from "../images/icons/home-phone.svg"
import envelope from "../images/icons/envelope.svg"

const Contact = ({ greyBackground }) => (
  <div className={`${greyBackground ? "bg-background" : ""}`}>
    <div className="container py-20">
      <div className="grid grid-cols-1 md:grid-cols-3">
        <div className="flex justify-center items-start"><img src={logoInverse} style={{'width':'194px'}} /></div>
        <div className="py-12 md:py-0 flex justify-center">
          <div className="flex flex-col text-body">
            <span className="font-bold mb-2">F.P.H.U Wojmal</span>
            <span className="mb-2">Robert Wojtycza</span>
            <span className="mb-2">ul. Sienkiewicza 32</span>
            <span>32-400 Myślenice</span>
          </div>
        </div>
        <div className="flex justify-center">
          <div className="flex flex-col">
            <div className="mb-2 flex items-center"><img src={phone} className="mr-2" /><span>606 135 787</span></div>
            <div className="mb-2 flex items-center"><img src={homePhone} className="mr-2"/><span>12 274 20 84</span></div>
            <div className="flex items-center"><img src={envelope} className="mr-2"/><span>biuro@wojmal.pl</span></div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Contact
