import * as React from "react"
import { Link } from "gatsby"

const OfferLayout = ({ title, list, image }) => {

  return (
    <div className="flex flex-wrap lg:flex-nowrap">
      <div className="w-full lg:w-72 mr-0 lg:mr-16 mb-10 lg:mb-0">
        <div className="bg-background rounded-lg p-8 flex flex-col text-lg text-body">
          <Link className="py-2 hover:text-primary transition-150" activeClassName="text-primary" to="/oferta/angielskie">Pędzle Angielskie</Link>
          <Link className="py-2 hover:text-primary transition-150" activeClassName="text-primary" to="/oferta/do-akryli">Pędzle do Akryli</Link>
          <Link className="py-2 hover:text-primary transition-150" activeClassName="text-primary" to="/oferta/kaloryferowe">Pędzle Kaloryferowe</Link>
          <Link className="py-2 hover:text-primary transition-150" activeClassName="text-primary" to="/oferta/lawkowce">Pędzle Ławkowce</Link>
          <Link className="py-2 hover:text-primary transition-150" activeClassName="text-primary" to="/oferta/okragle">Pędzle Okrągłe</Link>
          <Link className="py-2 hover:text-primary transition-150" activeClassName="text-primary" to="/oferta/polangielskie">Pędzle Półangielskie</Link>
          <Link className="py-2 hover:text-primary transition-150" activeClassName="text-primary" to="/oferta/paskowe">Pędzle Paskowe</Link>
          <Link className="py-2 hover:text-primary transition-150" activeClassName="text-primary" to="/oferta/profesjonalne">Pędzle Profesjonalne</Link>
          <Link className="py-2 hover:text-primary transition-150" activeClassName="text-primary" to="/oferta/tapetowe">Pędzle Tapetowe</Link>
          <Link className="py-2 hover:text-primary transition-150" activeClassName="text-primary" to="/oferta/lawkowczyki">Ławkowczyki</Link>
          <Link className="py-2 hover:text-primary transition-150" activeClassName="text-primary" to="/oferta/szczotki">Szczotki</Link>
          <Link className="py-2 hover:text-primary transition-150" activeClassName="text-primary" to="/oferta/zamiatacze">Zamiatacze</Link>
          <Link className="py-2 hover:text-primary transition-150" activeClassName="text-primary" to="/oferta/zmiotki">Zmiotki</Link>
          {/* <Link className="py-2 hover:text-primary transition-150" activeClassName="text-primary" to="/oferta/inne">Inne</Link> */}
        </div>
      </div>
      <div className="flex-1">
        <h3 className="text-2xl font-bold mb-4 text-primary">{title}</h3>
        <img src={image} alt={title} className="rounded-md w-full" style={{}}/>
        <div className="pt-6">
          {list.map((data, index) => {
            return (
              <div key={index} className="py-2 pl-1 border-b border-gray-200 last:border-0 font-bold text-body text-lg">{data}</div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default OfferLayout
